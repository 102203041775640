.dashboardPage {
    display: flex;
    flex-direction: row;
}

.content {
    margin-right: 25px;
    margin-top: 10px;
    margin-left: 25px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}