@media only screen 
  and (min-device-width: 601px) { 
    .headline {
      color: $darkGrey;  
      font-size: 56px; 
      line-height: 1.07143;
      font-weight: 600; 
      letter-spacing: -.005em; 

      text-align: center;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      flex-shrink: 1;
    }

    .subhead {
      //max-width: 350px;
      font-size: 28px;
      line-height: 1.10722;
      font-weight: 400;
      letter-spacing: .004em;
      margin-top: 6px;

      text-align: center;
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      flex-shrink: 1;
    }

    .typography-intro {
      font-size: 21px;
      line-height: 1.38105;
      font-weight: 400;
      letter-spacing: .011em;
    }

    .typography-body {
      font-size: 17px;
      line-height: 1.47059;
      font-weight: 400;
      letter-spacing: -.022em;
    }
}

@media only screen 
  and (max-device-width: 600px){
    .headline {
      color: $darkGrey;  
      font-size: 32px; 
      line-height: 1.07143;
      font-weight: 600; 
      letter-spacing: -.005em; 

      text-align: center;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      flex-shrink: 1;
    }

    .subhead {
      max-width: 350px;
      font-size: 18px;
      line-height: 1.10722;
      font-weight: 400;
      letter-spacing: .004em;
      margin-top: 6px;

      text-align: center;
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      flex-shrink: 1;
    }
}




h2 { 
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  /*color: $darkGrey;  
  font-size: 30px; 
  font-weight: 300; 
  line-height: 32px; 
  margin: 0 0 72px; 
  text-align: center;*/
}


p { 
  color: $darkGrey; 
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

blockquote { 
  padding: 30px 40px; 
  font-size: 18px; 
  border-left: 5px solid $darkGrey; 
  margin: 20px 40px; 
  color: $darkGrey; 
}
