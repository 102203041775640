@import '../variables.scss';

.navBar {
    height: $navBarHeight;
    background-color: #333333 !important;


    flex-direction: row;
    align-items: left;
    justify-content: center;
    

    position: fixed;
    z-index: 9999 !important;
    box-shadow: none !important;
    
    //border-bottom: 1px solid $darkGrey !important; 
  }

  .toolBar {
    width: 98%;
  }

  .hiddenToolBar {
    height: $navBarHeight;
  }
  
  .logo {
    width: 150px;
    height: auto;
    padding: 5px 50px 0 0;
    pointer-events: none;
  }

  .spacer {
    flex: 1;
  }
  
  .navLink {
    &:hover {
      color: darken($white, 20%);
    }
  
    text-decoration: none;
    //color: $darkGrey;  
    padding-right: 25px;
    
    color: $white !important;
  }