$purple: #7a3574;
$white: white;
$darkGrey: #393A3D;
$grey: lighten($darkGrey, 70%);
$lightGrey: #D4D7DC;
$green: #8ac440;
$black: black;

$navBarHeight: 75px;



@mixin heroImage($imageUrl) {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    //background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url($imageUrl);
    background-image: url($imageUrl);
  
    /* Set a specific height */
    height: 400px;
  
    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    /* Place text in the middle of the image */
    .heroText {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        color: $white;
        font-size: 60px;

        h1 {
            color: $white;
        }
    }
  }